<template>
  <div class="VerificationsCheck dselect">
    <div class="content">
      <ListItemCheck :text="$locale['reject']" @click="$emit('select', 'rejected')" :checked="selected === 'rejected'" rejec />
      <ListItemCheck :text="$locale['accept']" @click="$emit('select', 'accept')" :checked="selected === 'accept'" accept />
    </div>
  </div>
</template>

<script>
export default {
  props: ["selected"],
};
</script>

<style lang="scss">
.VerificationsCheck {
  overflow: hidden;
  .content {
    display: flex;
    align-items: center;
  }
  .list-item[rejec],
  .list-item[accept] {
    flex: 0.5;
    padding: $mpadding;
    &:not(:first-child) {
      border-left: solid 1px $alto;
    }
  }
  .list-item[rejec] {
    .mycheck:checked:before {
      content: "\2613";
    }
  }
}
</style>
